import React from "react";

export default function LtcIco() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_759_93297)">
        <path
          d="M2.14376e-07 15.4226C0.0294959 7.1359 6.33175 1.35431 12.4275 0.290725C17.8267 -0.652033 22.5446 0.677101 26.4563 4.52822C29.4957 7.52087 30.9846 11.2399 31 15.4971C31.0323 24.0325 24.0053 31.0139 15.4558 30.9985C6.89919 30.983 -0.00140434 24.0283 2.14376e-07 15.4226ZM8.62539 18.1413C9.35155 17.9446 10.0777 17.7479 10.8952 17.5273C10.1479 20.0633 9.43161 22.4926 8.71809 24.9176C8.81079 24.9542 8.85433 24.9865 8.89647 24.9865C13.1733 24.9921 17.4516 24.9935 21.7285 25.0005C22.1429 25.0019 22.3718 24.8193 22.4771 24.4189C22.6499 23.7655 22.8536 23.1192 23.0404 22.4701C23.3044 21.5526 23.2244 21.4417 22.2889 21.4417C19.7649 21.4402 17.2395 21.429 14.7156 21.4515C14.2169 21.4557 14.1341 21.3461 14.29 20.8712C14.6734 19.6966 15.0807 18.5192 15.3251 17.3123C15.533 16.2839 16.0232 15.6882 17.0598 15.6053C18.0345 15.5266 18.4334 14.9618 18.6104 14.0584C18.7214 13.4921 18.5402 13.523 18.1399 13.6537C17.571 13.8392 16.9952 14.0036 16.3153 14.2087C16.3982 13.9122 16.4502 13.7198 16.5064 13.5287C17.2718 10.9097 18.0401 8.29082 18.8042 5.67048C19.043 4.84996 18.9545 4.73897 18.0851 4.73616C17.2199 4.73335 16.3533 4.77269 15.4909 4.72351C14.8701 4.68698 14.6229 4.96798 14.4641 5.51453C13.6804 8.22619 12.7731 10.9055 12.1129 13.6467C11.7449 15.1697 11.1381 16.1701 9.49762 16.3246C9.22654 16.3499 8.95546 16.4553 8.87821 16.7967C8.7813 17.2266 8.66191 17.6524 8.55095 18.0795C8.5355 18.1076 8.52145 18.1371 8.506 18.1652C8.54674 18.1553 8.58606 18.1483 8.62539 18.1413Z"
          fill="#345D9D"
        />
        <path
          d="M8.55371 18.0768C8.66327 17.6497 8.78406 17.224 8.88097 16.794C8.95822 16.4526 9.2293 16.3486 9.50038 16.3219C11.1409 16.1674 11.7477 15.167 12.1157 13.644C12.7772 10.9042 13.6846 8.2235 14.4669 5.51184C14.6256 4.96389 14.8728 4.6843 15.4936 4.72083C16.356 4.77141 17.2226 4.73066 18.0878 4.73347C18.9573 4.73628 19.0458 4.84868 18.807 5.6678C18.0429 8.28813 17.276 10.9057 16.5091 13.526C16.4529 13.7171 16.401 13.9096 16.3181 14.206C16.9979 14.0009 17.5724 13.8351 18.1426 13.651C18.5429 13.5204 18.7241 13.4895 18.6132 14.0557C18.4362 14.9591 18.0373 15.5239 17.0625 15.6026C16.0246 15.6869 15.5344 16.2812 15.3279 17.3097C15.0849 18.5166 14.6762 19.694 14.2927 20.8685C14.1382 21.3434 14.2197 21.453 14.7183 21.4488C17.2423 21.4263 19.7677 21.4376 22.2917 21.439C23.2257 21.439 23.3058 21.55 23.0431 22.4674C22.8563 23.1179 22.6527 23.7628 22.4799 24.4162C22.3746 24.8166 22.1442 24.9992 21.7313 24.9978C17.4544 24.9908 13.1761 24.9894 8.89923 24.9838C8.85569 24.9838 8.81355 24.9515 8.72085 24.9149C9.43577 22.4899 10.1507 20.0607 10.8979 17.5246C10.0791 17.7466 9.35431 17.9433 8.62815 18.1386C8.60147 18.1189 8.57759 18.0979 8.55371 18.0768Z"
          fill="#FDFDFD"
        />
        <path
          d="M8.62537 18.1414C8.58604 18.1484 8.54812 18.1555 8.50879 18.1625C8.52424 18.1344 8.53828 18.1049 8.55373 18.0768C8.57761 18.0979 8.60149 18.1189 8.62537 18.1414Z"
          fill="#FDFDFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_759_93297">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
