import React from "react";
import SignUp from "../components/AuthPages/SignUp";

function SignupPage() {
  return (
    <>
      <SignUp />
    </>
  );
}

export default SignupPage;
